<template>
  <div class="rowP" :class="{ showAll: open }">
    <h1 class="rowTitle" @click="open = !open">
      {{ txt }}
      <img class="rowJianTou" :src="require('@/assets/jiantou.png')" alt="" />
    </h1>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    txt: "",
  },
  data() {
    return {
      open: false,
    };
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.rowP {
  max-height: 78px;
  transition: 0.4s;
  overflow: hidden;
  .rowTitle {
    font-size: 30px;
    font-weight: 400;
    color: #272727;
    line-height: 78px;
    display: flex;
    padding: 0 30px;
    justify-content: space-between;
    align-items: center;
    .rowJianTou {
      width: 22px;
      height: 14px;
      transform: rotate(-90deg);
      transition: 0.4s;
    }
  }
  &.showAll {
    max-height: 1200px;
    .rowJianTou {
      transform: rotate(0deg);
    }
  }
}
</style>
