<template>
  <div
    class="tabBar"
    v-if="!$route.query.nofooter && !$route.query.nohead && !$route.query.title"
  >
    <div class="tabOpt" @click="openuserMenu">
      <img
        v-if="!menuOpen"
        :src="require('@/assets/homePage/xiaofangzi.png')"
        alt=""
      />
      <img
        class="actImg"
        v-else
        :src="require('@/assets/homePage/redfangzi.png')"
        alt=""
      />
      <span :class="{ sctiveSpan: menuOpen }">{{ $fanyi("首页") }}</span>
    </div>
    <div class="tabOpt" @click="openclassificationofgoods">
      <img
        v-if="!catgOpen"
        :src="require('@/assets/homePage/fei.png')"
        alt=""
      />
      <img v-else :src="require('@/assets/homePage/fenlei.png')" alt="" />
      <span :class="{ sctiveSpan: catgOpen }">{{ $fanyi("分类") }}</span>
    </div>

    <div class="tabOpt" @click="toPage('/cart')">
      <div class="haveNum" v-if="dataCount && $store.state.userInfo">
        {{ dataCount }}
      </div>
      <img
        v-if="act('cart')"
        :src="require('@/assets/homePage/购物车.png')"
        alt=""
      />
      <img v-else alt="" :src="require('@/assets/homePage/gouwu.png')" />
      <span :class="{ sctiveSpan: act('cart') }">{{ $fanyi("购物车") }}</span>
    </div>
    <div class="tabOpt" @click="openChat">
      <div class="haveNum" v-if="unreadMessage && $store.state.userInfo">
        {{ unreadMessage }}
      </div>
      <img
        v-if="$store.state.showChat"
        :src="require('@/assets/homePage/redxiaoxi.png')"
        alt=""
      />
      <img v-else :src="require('@/assets/homePage/xiaoxi.png')" alt="" />
      <span :class="{ sctiveSpan: $store.state.showChat }">{{
        $fanyi("聊天")
      }}</span>
    </div>
    <div class="tabOpt" @click="toPage('/user/index')">
      <van-image lazy-load class="userImg" v-if="userImg" :src="userImg" alt="">
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
      <img
        class="userImg"
        v-else
        :src="require('@/assets/homePage/d3.png')"
        alt=""
      />
      <span :class="{ sctiveSpan: act('userIndex') }">{{
        $fanyi("个人中心")
      }}</span>
    </div>
  </div>
  <userMenu ref="userMenu" />
  <classificationofgoods ref="classificationofgoods" />
  <div class="footbg"></div>
</template>
<script>
import userMenu from "./components/userDetail";
import classificationofgoods from "./components/Classification-of-goods";
import { getCurrentInstance, computed } from "vue";
import { mapState, mapMutations, mapActions } from "vuex";
import { ref, onMounted } from "vue";
export default {
  setup(props) {
    const { proxy } = getCurrentInstance();
    const menuOpen = ref(false);
    const catgOpen = ref(false);
    onMounted(() => {
      proxy.$store.dispatch("getTimMessageUnreadNum");
      proxy.$store.dispatch("goodsToCartNum");
    });
    const dataCount = computed(() => {
      return proxy.$store.state.cartCount.dataCount;
    });
    const unreadMessage = computed(() => {
      return proxy.$store.state.unreadMessage;
    });
    const userImg = computed(() => {
      if (
        proxy.$store.state.userInfo &&
        proxy.$store.state.userInfo &&
        proxy.$store.state.userInfo.portrait_url
      ) {
        return proxy.$store.state.userInfo.portrait_url;
      }
      return false;
    });
    // 打开聊天框
    const openChat = () => {
      // if (!proxy.$store.state.userInfo) {
      //   return proxy.$store.commit(
      //     "changeshowLoginDia",
      //     !proxy.$store.state.showLoginDia
      //   );
      // }
      // 关闭其他的两个弹窗和底部图标显示
      menuOpen.value = false;
      catgOpen.value = false;
      proxy.$refs.classificationofgoods.show = false;
      proxy.$refs.userMenu.show = false;
      // 打开聊天弹窗
      proxy.$store.commit("changeshowChat", !proxy.$store.state.showChat);
    };
    // 打开用户操作详情
    const openuserMenu = () => {
      if (proxy.$route.name != "homepage") {
        return proxy.$fun.routerToPage("/");
      }
      proxy.$refs.userMenu.open();
      proxy.$refs.classificationofgoods.show = false;
      proxy.$store.commit("changeshowChat", false);
      catgOpen.value = false;
    };
    //打开商品分类
    const openclassificationofgoods = () => {
      proxy.$refs.classificationofgoods.open();
      proxy.$refs.userMenu.show = false;
      proxy.$store.commit("changeshowChat", false);
      menuOpen.value = false;
    };
    const toPage = (v) => {
      if (proxy.$store.state.userInfo) {
        proxy.$refs.userMenu.show = false;
        proxy.$store.commit("changeshowChat", false);
        proxy.$store.commit("changeshowLoginDia", false);
        proxy.$fun.routerToPage(v);
      } else {
        proxy.$store.commit(
          "changeshowLoginDia",
          !proxy.$store.state.showLoginDia
        );
      }
    };
    const act = (v) => {
      if (proxy.$route.name == v) {
        return true;
      }
      return false;
    };
    return {
      // 参数
      menuOpen,
      catgOpen,
      // 计算属性
      dataCount,
      unreadMessage,
      userImg,
      // 事件
      openChat,
      openuserMenu,
      openclassificationofgoods,
      toPage,
      act,
    };
  },

  components: {
    userMenu,
    classificationofgoods,
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
.footbg {
  height: 119px;
}

.tabBar {
  position: fixed;
  // top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 119px;
  background: #f9f9f9;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 13333;

  .tabOpt {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;

    img {
      margin-bottom: 10px;
      width: 40px;
      height: 40px;
    }

    .haveNum {
      position: absolute;
      background-color: #faced0;
      width: 40px;
      height: 40px;
      font-size: 20px;
      color: #b4272b;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      top: -20px;
      right: 25px;
    }

    // 用户头像css
    .userImg {
      border-radius: 50%;
      overflow: hidden;
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      color: #2f2f2f;

      &.sctiveSpan {
        color: #b4272b;
      }
    }
  }
}
</style>
<style lang="scss">
// 确认框
.el-message-box {
  max-width: 485px;

  .el-message-box__header {
    .el-message-box__close {
      font-size: 25px;
    }
  }

  .el-message-box__message {
    padding: 20px 0 20px;

    p {
      font-size: 24px;
      line-height: 1.4;
      text-align: center;
    }
  }

  .el-button {
    padding: 20px 50px;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &.el-button--primary {
      background-color: #b4272b;
      outline: none;
    }
  }
}
</style>
