<template>
  <div class="jisn">
    <van-popup
      v-model:show="show"
      position="left"
      :style="{ height: '100%', width: '100%' }"
    >
      <div class="bg">
        <div class="zuJianHead">
          <div class="logoBox">
            <img :src="require('@/assets/icon/app-logo.png')" alt="" />
          </div>
          <div
            class="closeBtn"
            @click="
              show = !show;
              $parent.menuOpen = show;
            "
          >
            <img :src="require('@/assets/icon/cha2.png')" alt="" />
          </div>
        </div>
        <div class="goodsMenu">
          <div
            class="oneF"
            :class="{ openoneF: oneFItem.checked }"
            v-for="oneFItem in lists"
            :key="oneFItem.id"
          >
            <h1
              class="goodsOneFH1"
              @click="oneFItem.checked = !oneFItem.checked"
              :class="{ open: oneFItem.checked }"
            >
              <div class="linkBox">
                <div class="icon">
                  <van-image
                    lazy-load
                    class="iconBox"
                    :src="oneFItem.icon_default"
                  >
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                </div>
                <!-- <img
                  class="icongoods"
                  v-if="!oneFItem.checked"
                  :src="oneFItem.default_icon"
                  alt=""
                />
                <img
                  class="icongoods"
                  v-else
                  :src="oneFItem.trigger_icon"
                  alt=""
                /> -->
                <span
                  class="goodsTitle"
                  @click="
                    $fun.routerToPage(
                      '/CommoditySearch?keyword=' +
                        oneFItem.translate_name +
                        '&CN=' +
                        $fun.toCode(oneFItem.chinese_name)
                    )
                  "
                  >{{ oneFItem.translate_name }}</span
                >
              </div>
              <img
                class="rowJianTou"
                :src="require('@/assets/jiantou.png')"
                alt=""
              />
            </h1>
            <div v-for="twoFItem in oneFItem.childs" :key="twoFItem.id">
              <div
                class="twoF"
                v-for="threeFItem in twoFItem.childs"
                :key="threeFItem.id"
                @click="
                  $fun.routerToPage(
                    '/CommoditySearch?keyword=' +
                      threeFItem.translate_name +
                      '&CN=' +
                      $fun.toCode(threeFItem.chinese_name)
                  )
                "
              >
                {{ threeFItem.translate_name }}
              </div>
            </div>
          </div>
        </div>
        <div class="technicalSupport">
          {{ $fanyi("由1688提供部分技术服务⽀持") }}
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Popup } from "vant";
export default {
  setup() {},
  data() {
    return {
      lists: [],
      show: false,
    };
  },
  components: {
    [Popup.name]: Popup,
  },
  computed: {},
  created() {
    this.getData();
  },
  methods: {
    open() {
      this.show = !this.show;
      this.$parent.catgOpen = this.show;
    },
    // 获取搜索框分类数据和首页分类数据
    getData() {
      this.$api
        .getShopGroup(this.form)
        .then((result) => {
          this.changeData(result.data);
          this.lists = result.data;
          // this.$store.commit("getgoodsList", result.data);
        })
        .catch((err) => {});
    },
    // 子分类排序
    changeData(data) {
      //("分类数据", data);
      data.forEach((data1Item) => {
        data1Item.checked = false;
        data1Item.childs.sort(duiBi);
      });
      function duiBi(a, b) {
        // time 是时间的字段 a-b 正序 b-a 倒序
        return Date.parse(b.sort) - Date.parse(a.sort);
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.bg {
  background-color: white;
  position: relative;
  padding-bottom: 200px;
  overflow: auto;
  height: 100%;
  .zuJianHead {
    height: 100px;
    display: flex;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .logoBox {
      img {
        width: 400px;
      }
    }

    .closeBtn {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .goodsMenu {
    // 一级分类
    .oneF {
      max-height: 100px;
      overflow: hidden;
      transition: 0.5s;

      &.openoneF {
        max-height: 1600px;
      }

      .goodsOneFH1 {
        margin-top: 20px;
        padding: 0px 40px;
        display: flex;
        align-items: center;

        .linkBox {
          padding: 20px 0;
          display: flex;
          align-items: center;

          .icon {
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-right: 10px;
            .iconBox {
              width: auto;
              height: 30px;
            }
          }

          .goodsTitle {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: -webkit-box;
          }
        }

        .icongoods {
          width: 40px;
          height: 40px;

          margin-right: 16px;
        }

        .goodsTitle {
          font-size: 30px;
          font-weight: 400;
          line-height: 30px;
          transition: 0.3s;
        }

        .rowJianTou {
          margin-left: auto;
          width: 22px;
          height: 14px;
          transform: rotate(-90deg);
          transition: 0.3s;
        }
        &.open {
          .goodsTitle {
            color: #b4272b;
          }
          .rowJianTou {
            transform: rotate(0deg);
          }
        }
      }
    }
    .twoF {
      font-size: 30px;
      font-weight: 400;
      color: #272727;
      padding: 0 0 0 93px;
      line-height: 80px;
      height: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 90%;

      &:active {
        color: #b4272b;
      }
    }
  }
  .technicalSupport {
    height: 80px;
    width: 100%;
    font-size: 20px;
    color: #999999;
    line-height: 80px;
    text-align: center;
    position: fixed;
    background-color: #f6f6f6;
    bottom: 118px;
  }
}
</style>
