<template>
  <van-popup
    v-model:show="show"
    position="left"
    :style="{ height: '100%', width: '100%' }"
  >
    <div class="page">
      <div class="zuJianHead">
        <img
          class="logoBox"
          :src="require('@/assets/icon/app-logo.png')"
          alt=""
        />
        <div
          class="closeBtn"
          @click="
            show = !show;
            $parent.menuOpen = show;
          "
        >
          <img :src="require('@/assets/icon/cha2.png')" alt="" />
        </div>
      </div>
      <div class="userInfoBar">
        <van-image
          lazy-load
          class="userImg"
          v-if="$store.state.userInfo?.portrait_url"
          :src="$store.state.userInfo.portrait_url"
          alt=""
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
        <img
          class="userImg"
          v-else
          :src="require('@/assets/homePage/d3.png')"
          alt=""
        />
        <div class="userLoginInfoBox" v-if="$store.state.userInfo?.username">
          <p class="toUserIndex">
            {{ $store.state.userInfo?.username }} 樣&nbsp;
          </p>
        </div>
        <div class="loginBox" v-else>
          <span
            @click="
              open();
              login();
            "
            >{{ $fanyi("登录") }}</span
          >
          <span class="fengexian">/</span>
          <span
            @click="
              open();
              $fun.routerToPage('/register');
            "
            >{{ $fanyi("注册") }}</span
          >
        </div>
      </div>
      <div class="gongSiJieShao">
        <p
          class="jieShaoLink height100 indexPage"
          @click="
            open();
            $fun.routerToPage('/');
          "
        >
          {{ $fanyi("首页") }}
        </p>
        <!-- $%1 -->
        <!-- <p
          class="jieShaoLink height100"
          @click="
            open();
            $fun.routerToPage('/quienes-somos');
          "
        >
          {{ $fanyi("关于rakumart") }}
        </p> -->
        <p
          class="jieShaoLink"
          @click="
            open();
            $fun.routerToPage('/guia-de-usuario');
          "
        >
          {{ $fanyi("用户指南") }}
        </p>
        <p
          class="jieShaoLink"
          @click="
            open();
            $fun.routerToPage('/internationalLogistics');
          "
        >
          {{ $fanyi("国际运费详情") }}
        </p>

        <p
          class="jieShaoLink"
          @click="
            open();
            $fun.routerToPage('/comisiones-rakumart');
          "
        >
          {{ $fanyi("关于手续费") }}
        </p>
        <p
          class="jieShaoLink"
          @click="
            open();
            $fun.routerToPage('/option');
          "
        >
          {{ $fanyi("附加服务费用说明") }}
        </p>
        <p
          class="jieShaoLink"
          @click="
            open();
            $fun.routerToPage('/tollVipApplyFor');
          "
        >
          {{ $fanyi("定额会员") }}
        </p>
      </div>
      <!-- <div class="rowHr" /> -->
      <row class="rowMar height100" :txt="$fanyi('其他说明')">
        <div class="linkB">
          <p
            class="LinkOpt"
            @click="
              open();
              $fun.routerToPage('/register');
            "
          >
            {{ $fanyi("免费注册会员") }}
          </p>
          <p
            class="LinkOpt"
            @click="
              open();
              $fun.routerToPage('/disclaimer');
            "
          >
            {{ $fanyi("退货、退款及免责事项") }}
          </p>
          <p
            class="LinkOpt"
            @click="
              open();
              $fun.routerToPage('/membershipUtilizationProtocol');
            "
          >
            {{ $fanyi("会员利用规章") }}
          </p>
          <p
            class="LinkOpt"
            @click="
              open();
              $fun.routerToPage('/privacyPolicy');
            "
          >
            {{ $fanyi("隐私政策") }}
          </p>
          <p
            class="LinkOpt"
            @click="
              open();
              $fun.routerToPage('/inspectionDetail');
            "
          >
            {{ $fanyi("普检精检详细说明") }}
          </p>
          <p
            class="LinkOpt"
            @click="
              open();
              $fun.routerToPage('/option');
            "
          >
            {{ $fanyi("option公司费用介绍") }}
          </p>

          <p
            class="LinkOpt"
            @click="
              open();
              $fun.routerToPage('/storageInstructions');
            "
          >
            {{ $fanyi("仓库保管说明") }}
          </p>
          <p
            class="LinkOpt"
            @click="
              open();
              $fun.routerToPage('/japanCompanyIntroduce');
            "
          >
            {{ $fanyi("日本公司") }}
          </p>
          <p
            class="LinkOpt"
            @click="
              open();
              $fun.routerToPage('/chinaCompanyIntroduce');
            "
          >
            {{ $fanyi("中国公司") }}
          </p>
          <p
            class="LinkOpt"
            @click="
              open();
              $fun.routerToPage('/questionAndAnswer');
            "
          >
            {{ $fanyi("よくある質問") }}
          </p>
          <p
            class="LinkOpt"
            @click="
              open();
              $fun.routerToPage('/accountSynchronization');
            "
          >
            RAKUAMRT&1688
          </p>
          <p
            class="LinkOpt"
            @click="
              open();
              $fun.routerToPage('/forwardToWarehouse');
            "
          >
            {{ $fanyi("仓库交付和转让") }}
          </p>
        </div>
      </row>
      <div class="technicalSupport">
        {{ $fanyi("由1688提供部分技术服务⽀持") }}
      </div>
    </div>
  </van-popup>
</template>
<script>
import row from "./components/row.vue";
import { Popup } from "vant";
export default {
  data() {
    return {
      show: false,
    };
  },
  components: {
    row,
    [Popup.name]: Popup,
  },
  computed: {
    userImg() {
      if (
        this.$store.state.userInfo &&
        this.$store.state.userInfo &&
        this.$store.state.userInfo.head_img
      ) {
        return this.$store.state.userInfo.head_img;
      }
      return false;
    },
    userInfo() {
      if (this.$store.state.userInfo) {
        return this.$store.state.userInfo;
      }
      return false;
    },
  },
  created() {},
  methods: {
    // 打开
    open() {
      this.show = !this.show;
      this.$parent.menuOpen = this.show;
    },
    // getUser() {
    //   this.$api.EuropegetUserInfo().then((res) => {
    //     this.$store.commit("userData", res.data);
    //   });
    // },
    // 退出登录
    loginOut() {
      localStorage.removeItem("japan_user_token");
      this.$store.commit("userData", null);
      this.$store.commit("setcartCount", 0);
      let requireAuth = this.$route.matched[0].meta.requireAuth;
      if (requireAuth) {
        this.$fun.routerToPage("/");
      }
    },
    login() {
      this.$store.commit("changeshowLoginDia", () => {
        this.$fun.toPage("/");
      });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.page {
  overflow: auto;
  background-color: white;
  padding: 0 0px 190px;
  height: 100%;
  position: relative;
  // display: flex;
  // flex-direction: column;
  :deep().van-image__loading {
    // width: 80px;
    // height: 80px;
    // border-radius: 50%;
    background: transparent;
  }

  .zuJianHead {
    height: 120px;
    display: flex;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    .logoBox {
      width: 400px;
    }

    .closeBtn {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .userInfoBar {
    padding: 30px 30px 20px;
    min-height: 130px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 100px;
    z-index: 1;
    background-color: white;

    .userImg {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 35px;

      :deep().van-image__img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
    }

    .userLoginInfoBox {
      font-size: 30px;
      font-weight: 400;
      color: #272727;
      line-height: 30px;
      font-size: 32px;

      font-weight: 600;

      color: #000000;

      // 用户操作
      .userO {
        margin-top: 10px;
        span {
          font-size: 20px;
          line-height: 30px;
        }
        display: flex;
        align-items: center;
      }
    }

    .loginBox {
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        display: inline-block;
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        color: #272727;
      }
      .fengexian {
        margin: 15px;
      }
    }
  }

  .gongSiJieShao {
    .jieShaoLink {
      height: 80px;
      display: flex;
      align-items: center;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;

      padding: 0 30px;

      &:active {
        background: #f5f7fa;
        color: #b4272b;
      }
    }
    .indexPage {
      color: #b4272b;
    }
  }

  .rowHr {
    background-color: transparent;
    border-top: solid 1px #e8e8e8;
    height: 1px;
    margin: 100px 21px 0;
  }

  .rowMar {
    margin: 0 0 0px;

    .linkB {
      margin-top: 10px;
      .LinkOpt {
        font-size: 28px;
        font-weight: 400;
        color: #272727;
        line-height: 71px;
        padding: 0 30px;

        &:active {
          background: #f5f7fa;
          color: #1a289d;
        }
      }
    }
  }

  .height100 {
    min-height: 100px;
    font-size: 30px !important;
    font-family: PingFang SC;
    font-weight: 600 !important;
    color: #000000;

    &:active {
      background: #f5f7fa;
      color: #b4272b;
    }
  }

  :deep() .rowTitle {
    font-size: 30px !important;
    font-family: PingFang SC;
    font-weight: 600 !important;
    color: #000000;

    &:active {
      background: #f5f7fa;
      color: #b4272b;
    }
  }
  .technicalSupport {
    height: 80px;
    width: 100%;
    font-size: 20px;
    color: #999999;
    line-height: 80px;
    text-align: center;
    position: fixed;
    background-color: white;
    bottom: 118px;
  }
}
</style>
